export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';


export const TRANSACTINO_VERIFYED = 'TRANSACTINO_VERIFYED';
export const SET_VLOAD = 'SET_VLOAD';

export const GET_SUBACCOUNT = 'GET_SUBACCOUNT';

export const GET_BALANCES = 'GET_BALANCES';
export const GET_BALANCE = 'GET_BALANCE';
export const UPDATE_BALANCE = 'UPDATE_BALANCE';
export const GET_ROK_FUND_BALANCE = 'GET ROK FUND BALANCE';
export const GET_YMIR_FUND_BALANCE = 'GET YMIR FUND BALANCE';

export const GET_LOGS = 'GET_LOGS';

export const SET_MODAL = 'SET_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';


export const GET_YMIR_WALLET_BALANCE = "GET_YMIR_WALLET_BALANCE";
export const GET_YMIR_CONTRACT_BALANCE = "GET_YMIR_CONTRACT_BALANCE";

export const GET_ROK_WALLET_BALANCE = "GET_ROK_WALLET_BALANCE";
export const GET_ROK_CONTRACT_BALANCE = "GET_ROK_CONTRACT_BALANCE";

export const GET_YMIR_SUB_BALANCE = "GET_YMIR_SUB_BALANCE";
export const SET_LOADER = "SET_LOADER";
export const GET_YMIR_BALANCE = "GET_YNIR_BALANCE";
export const GET_ROK_BALANCE = "GET_ROK_BALANCE";
export const GET_USER = "GET_USER";
export const GET_TRANSACTION = "GET_TRANSACTION";

export const AFTER_LOGIN = 'AUTH ACTION AFTER LOGIN';
export const ADD_NEWS_RT = 'NEWS ACTION ADD NEWS';
export const SET_NEWS_RT = 'NEWS ACTION GET NEWS';

export const SET_CHARACTERS = 'CHRACTER ACTION SET CHARACTERS';
export const SET_MINTER_KEY = 'MINTER ACTION SET MINTER KEY';

export const SET_ALL_USERS = 'USER ACTION SET ALL USERS';

export const GET_YMIR_FUND_BALANCES = 'GET YMIR FUND BALANCES';
export const GET_ROK_FUND_BALANCES = 'GET ROK FUND BALANCES';
export const GET_YMIR_WALLET_BALENCES = 'GET WALLET BALENCES';
export const GET_ROK_WALLET_BALANCES = 'GET ROK WALLET BALANCES';

export const SET_MODE = 'MODE ACTION SET MODE';
export const TRANSACTION_VERIFYED_ROK = 'TRANSACTION VERIFIED ROK';
export const SET_USER_DATA = 'USER DATA ACTION SET USER DATA';
export const SET_ALL_LOGS = 'LOG ACTION SET ALL LOGS';